<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> {{ $t('Mi perfil') }}
      </h2>
    </div>
    <b-row>
      <b-col v-if="userCharge" cols="12">
        <b-card class="height-card">
          <div class="text-right">
            <b-link v-if="currentUser.roles[0].name === 'super_admin'" :to="{ name: 'editUser', params: { id: user.id } }"
              class="btn-primary mr-1">
              <span><feather-icon icon="Edit2Icon" /> Editar</span>
            </b-link>

            <b-link v-if="currentUser.id == user.id" class="btn-primary" @click="logout">
              <span> <feather-icon icon="LogOutIcon" /> {{ $t('Cerrar sesión') }}</span>
            </b-link>
          </div>
          <hr>
          <b-row class="mb-3">

            <b-col md="6" sm="12" class="d-flex">
              <div class="pr-2">
                <b-avatar size="6rem" :src="user.avatar" />
              </div>
              <div>
                <h4 class="mt-1 font-weight-bolder">
                  {{ user.name }} {{ user.surname }}
                </h4>
                <p v-if="user.client.length > 0" class="">
                  {{ user.client[0].name }}
                </p>
                <p class=" mt-2">
                  {{ user.email }}
                </p>
              </div>
              <div class="text-right mt-1 pl-2">

              </div>
            </b-col>
            <b-col md="6" sm="12" class="text-right">

            </b-col>
          </b-row>

        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BAvatar, BRow, BCol, BCard, BLink, BCardTitle, BForm, BContainer, BFormGroup, BFormInput, BFormRadio,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BForm,
    BContainer,
    BFormGroup,
    BFormInput,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: '',
      files: [],
      profileFile: null,
      required,
      email,
      changePassword: false,
      collapseInfo: false,
      userCharge: false,
      currentPage: 1,
      pageLength: 100,
      shipping: '',
      billing: '',
      type: '',
      ClientCountry: '',
      ClientAddress: '',
      ClientCP: '',
      ClientTown: '',
      ClientProvince: '',
      ClientCity: '',
      ClientName: '',
      edit: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      currentUser: 'auth/getUser',
    }),

  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      list: 'addresses/getListAddress',
      addAddress: 'addresses/create',
      editAddress: 'addresses/edit',
      delete: 'addresses/delete',
    }),
    setClients() {
      if (this.user.clients) {
        this.clients = this.user.clients
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('order')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    chargeData() {
      if (this.user) {
        this.userCharge = true
      }
    },
  },
  async created() {
    await this.getUser(this.$route.params.id)
    await this.chargeData()
  },
}
</script>
